import React from "react";
import { Helmet } from "react-helmet";

interface SeoMetaProps {
  title?: string;
  description?: string;
}

const SeoMeta: React.FC<SeoMetaProps> = ({ title = "", description = ""}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default SeoMeta;
