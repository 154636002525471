import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Link,
  Tab,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CircleImage from "asset/images/circle-small.svg";
import { useEffect, useState } from "react";
import AllBlogs from "./all-blogs";
import { useNavigate } from "react-router-dom";
import Loader from "ui-component/Loader";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import parse from "html-react-parser";
import { useDebounce } from "forms/hooks/useDebounce";
import Slider from "react-slick";
import PlaceholderImage from "asset/images/no-image-available.png";
import pageMeta from "../../../utils/metaData.json"
import SeoMeta from "component/SeoMeta";

const Blogs = () => {
  const pageMetaData = pageMeta.blogs;
  const theme = useTheme();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [value, setValue] = useState("all");
  const [perPage, setPerPage] = useState(8);
  const [total, setTotal] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [trendingBlogs, setTrendingBlogs] = useState([]);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    getLatestBlogs();
    getTrendingBlogs();
    getCategories();
  }, []);

  const blogSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  useEffect(() => {
    getBlogs();
  }, [value, page, debouncedSearchTerm]);

  const getBlogs = () => {
    setIsLoading(true);
    const category = value === "all" ? "" : value;
    const fetchUrl =
      category === ""
        ? `&per_page=${perPage}&order=desc&orderby=date&search=${search}`
        : `&per_page=${perPage}&order=desc&orderby=date&categories=${category}&search=${search}`;
    fetch(
      `${process.env.REACT_APP_WORDPRESS_URL}posts?_embed&page=${
        page + 1
      }${fetchUrl}`
    )
      .then((res) => res.json())
      .then((response: any) => {
        setIsLoading(false);
        setBlogs(response);
        setTotal(response?.length);
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getLatestBlogs = () => {
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_WORDPRESS_URL}posts?_embed&page=1&per_page=2`
    )
      .then((res) => res.json())
      .then((response: any) => {
        setIsLoading(false);
        setLatestBlogs(response);
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getTrendingBlogs = () => {
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_WORDPRESS_URL}posts?_embed&order_by=views&page=1&per_page=4`
    )
      .then((res) => res.json())
      .then((response: any) => {
        setIsLoading(false);
        setTrendingBlogs(response);
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getCategories = () => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_WORDPRESS_URL}categories`)
      .then((res) => res.json())
      .then((response: any) => {
        setIsLoading(false);
        setCategories(response);
      })
      .catch((error: any) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      <SeoMeta {...pageMetaData} />
      {isLoading && <Loader />}
      <Breadcrumbs aria-label="breadcrumb" sx={{ my: 3 }}>
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/home");
          }}
        >
          Home
        </Link>
        <Typography variant="h5" color={theme.palette.primary.textLight}>
          Blogs
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <Typography variant="h2" color={theme.palette.primary.textBtn}>
            Trending Blogs
          </Typography>
          {/* <Box display={"flex"} justifyContent={"center"}> */}
          <Box
            className="slider-container"
            gap={2}
            mt={3}
            mb={5}
            ml={{ xs: "0px", sm: "-1rem" }}
            maxWidth={{ xs: "90vw", sm: "96vw", md: "73vw" }}
          >
            <Slider {...blogSettings}>
              {trendingBlogs.map((blog: any) => {
                return (
                  <Box
                    sx={{
                      borderRadius: "10px",
                      maxHeight: "400px",
                      overflowY: "hidden",
                    }}
                  >
                    <img
                      src={blog?.fimg_url || PlaceholderImage}
                      alt="Blogs"
                      style={{
                        width: "100%",
                      }}
                    />
                    <Typography variant="h3" marginTop={2}>
                      {parse(blog?.title?.rendered || "")}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      mt={1}
                      flexWrap="wrap"
                    >
                      <img
                        src={blog?._embedded?.author[0]?.avatar_urls[24]}
                        alt="Blogs"
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "15px",
                        }}
                      />
                      <Typography
                        variant="h5"
                        color={theme.palette.primary.textMid}
                        style={{ marginLeft: "8px" }}
                      >
                        {blog?._embedded?.author[0]?.name}
                      </Typography>
                      <Box
                        sx={{
                          order: { xs: 3, sm: 2 },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={CircleImage}
                          alt="Circle"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <Typography
                          variant="h5"
                          color={theme.palette.primary.textMid}
                        >
                          Published on {formatDateSring(blog?.date)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          order: { xs: 2, sm: 3 },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={CircleImage}
                          alt="Circle"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <Typography
                          variant="h5"
                          color={theme.palette.primary.textMid}
                        >
                          {blog?.reading_time}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Slider>
          </Box>
          {/* </Box> */}
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography variant="h2" color={theme.palette.primary.textBtn}>
            Latest Blogs
          </Typography>
          <Card
            sx={{
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              marginTop: "20px",
            }}
          >
            <CardContent>
              {latestBlogs?.map((blog: any, index: number) => {
                const tags = blog?._embedded["wp:term"][1].slice(0, 3);
                return (
                  <Box>
                    <Box>
                      {tags?.map((tag: any) => {
                        return <Chip label={tag?.name}></Chip>;
                      })}
                      {blog?._embedded["wp:term"][1]?.length > 3 && (
                        <Tooltip
                          title={blog?._embedded["wp:term"][1]
                            ?.map((item: any) => item.name)
                            .join(", ")}
                          disableHoverListener={
                            blog?._embedded["wp:term"][1]?.length <= 1
                          }
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            component={"span"}
                            color={theme?.palette?.primary?.iconOrange}
                          >
                            +{blog?._embedded["wp:term"][1]?.length - 1} more
                          </Typography>
                        </Tooltip>
                      )}
                    </Box>

                    <Typography
                      variant="subtitle2"
                      color={theme.palette.primary.textBtn}
                      marginTop={1}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/blog-details/${blog?.id}`);
                      }}
                    >
                      {parse(blog?.title?.rendered || "")}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "15px",
                      }}
                    >
                      <img
                        src={blog?._embedded?.author[0]?.avatar_urls[24]}
                        alt="Ramasamy natarajan"
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "15px",
                        }}
                      />
                      <Typography
                        variant="h5"
                        color={theme.palette.primary.textBtn}
                        style={{ marginLeft: "8px" }}
                      >
                        {blog?._embedded?.author[0]?.name}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        color={theme.palette.primary.textMid}
                      >
                        Published on {formatDateSring(blog?.date)}
                      </Typography>
                      <img
                        src={CircleImage}
                        alt="Circle"
                        style={{ width: "24px", height: "24px" }}
                      />
                      <Typography
                        variant="h6"
                        color={theme.palette.primary.textMid}
                      >
                        {blog?.reading_time}
                      </Typography>
                    </Box>
                    {index !== latestBlogs?.length - 1 && (
                      <Divider sx={{ my: 2 }} />
                    )}
                  </Box>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1.87,
          }}
        >
          <TabList
            variant="scrollable"
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                height: "3px",
              },
            }}
          >
            <Tab label="All" value="all" />
            {categories?.map((category: any) => {
              return <Tab label={category?.name} value={category?.id} />;
            })}
          </TabList>
        </Box>
        <TabPanel value={value}>
          <AllBlogs
            allBlogs={blogs}
            handleSelectBlogs={(id: string) => {
              navigate(`/employer/candidate-details/${id}`);
            }}
            handleChangePage={handleChangePage}
            handleSearch={(val: string) => {
              setSearch(val);
            }}
            paginationData={{ page: page, perPage: perPage, total: total }}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Blogs;

const formatDateSring = (dateString: any) => {
  const date = new Date(dateString);

  // Options for toLocaleString
  const options: any = {
    year: "numeric",
    month: "short",
    day: "2-digit", // Include day with 2 digits
  };

  return date.toLocaleString("en-US", options);
};
