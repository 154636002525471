import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ContactImage from "asset/images/contact-us.jpg";
import { InputField, PhoneField, SelectField } from "forms/hoc/formfield";
import Call from "asset/icons/phone.svg";
import { useAppSelector } from "store/store";
import Message from "asset/icons/blue-message.svg";
import Location from "asset/icons/blue-location.svg";
import { contactusValidation } from "forms/hooks/validateRules";
import useForm from "forms/hooks/useForm";
import { useNavigate } from "react-router-dom";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import Loader from "ui-component/Loader";
import pageMeta from "../../../../utils/metaData.json"
import SeoMeta from "component/SeoMeta";

const ContactUs = () => {
  const pageMetaData = pageMeta.contactUs;
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useAppSelector((state: any) => state?.user?.user);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (user?.id) {
      setPatchValue({
        iam: user?.role === "employer" ? "recruiter" : "candidate",
        name: user?.name,
        organization_name: user?.organization_name,
        email: user?.email,
        mobile_number: user?.country_code + user?.mobile_number,
      });
    }
  }, []);
  const contactValues = {
    iam: null,
    name: null,
    organization_name: null,
    email: null,
    mobile_number: "91",
    message: null,
  };
  const getCountryCode = (phoneNumber: string) => {
    const match = phoneNumber.match(/^\+(\d+)/);
    return match ? `+${match[1]}` : "";
  };

  const getPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/^\+\d+\s|\-/g, "");
  };

  const handleSubmitContact = () => {
    setIsLoading(true);
    const payload: any = {
      name: values.name,
      email: values.email,
      mobile_number: getPhoneNumber(values.mobile_number),
      country_code: getCountryCode(values.mobile_number),
      message: values.message,
      enquiry_type: "66852b32cee11743df522f95",
    };
    if (values.iam === "recruiter") {
      payload.organization_name = values.organization_name;
    }
    commonService
      .postService(
        `/enquiry/${values.iam === "recruiter" ? "employer" : "candidate"}`,
        payload
      )
      .then((response) => {
        setIsLoading(false);
        Success(response?.data?.message);
        resetForm();
      })
      .catch((error) => {
        setIsLoading(false);

        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const {
    values,
    errors,
    handleChange,
    setUpdateValue,
    setPatchValue,
    handleSubmit,
    resetForm,
  } = useForm(contactValues, handleSubmitContact, contactusValidation);

  return (
    <>
      <SeoMeta {...pageMetaData} />
      {isLoading && <Loader />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          backgroundColor: "#FFF1DE",
          py: { xs: 1, sm: 5 },
          px: { xs: "2%", sm: "7.5rem" },
          mx: { xs: "-2%", sm: "-7.5rem" },
          minHeight: "250px",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 600,
              color: theme?.palette?.primary?.textBtn,
              mt: "2rem",
            }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 500,
              color: theme?.palette?.primary?.textGrey,
              mt: "1rem",
            }}
          >
            Have Questions? Contact us and we'll get back to you promptly
          </Typography>
        </Box>
        <Box>
          <Box
            component={"img"}
            src={ContactImage}
            alt={"ContactUs"}
            height={"250px"}
            sx={{ width: { xs: "100%", md: "auto" } }}
          ></Box>
        </Box>
        {/* <Grid container spacing={32}>
          <Grid item sm={6} xs={12}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 600,
                color: theme?.palette?.primary?.textBtn,
                mt: "2rem",
              }}
            >
              Contact Us
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 500,
                color: theme?.palette?.primary?.textGrey,
                mt: "1rem",
              }}
            >
              Have Questions? Contact us and we'll get back to you promptly
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <img
              src={ContactImage}
              alt={"ContactUs"}
              width={"250px"}
              height={"250px"}
            />
          </Grid>
        </Grid> */}
      </Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Card
                sx={{
                  boxShadow: `0px 0px 4px 0px ${theme?.palette?.primary?.boxShadowLight}`,
                  mt: "3rem",
                  mb: { sm: "5rem", xs: "2rem" },
                }}
              >
                <CardContent
                  sx={{
                    marginLeft: { xs: 0, sm: 0 },
                  }}
                >
                  {" "}
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <Typography
                        sx={{
                          mt: "2rem",
                          color: theme?.palette?.primary?.textBtn,
                        }}
                      >
                        {" "}
                        I am a
                      </Typography>
                      <Box sx={{ mt: "1rem" }}>
                        <SelectField
                          values={values?.iam}
                          errors={errors?.iam}
                          handleChange={handleChange}
                          name={"iam"}
                          options={[
                            {
                              name: "Recruiter",
                              value: "recruiter",
                            },
                            { name: "Candidate", value: "candidate" },
                          ]}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          mt: "2rem",
                          color: theme?.palette?.primary?.textBtn,
                        }}
                      >
                        Name
                      </Typography>
                      <Box sx={{ mt: "1rem", width: { xs: "fullwidth" } }}>
                        <InputField
                          values={values?.name}
                          name="name"
                          isRequired={false}
                          errors={errors?.name}
                          handleChange={handleChange}
                          placeholder=""
                          id="name"
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          mt: "2rem",
                          color: theme?.palette?.primary?.textBtn,
                        }}
                      >
                        Organisation Name
                      </Typography>
                      <Box sx={{ mt: "1rem" }}>
                        <InputField
                          values={values?.organization_name}
                          name="organization_name"
                          isRequired={false}
                          errors={errors?.organization_name}
                          handleChange={handleChange}
                          placeholder=""
                          id="organization_name"
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          mt: "2rem",
                          color: theme?.palette?.primary?.textBtn,
                        }}
                      >
                        Email ID
                      </Typography>
                      <Box sx={{ mt: "1rem" }}>
                        <InputField
                          values={values?.email}
                          name="email"
                          isRequired={false}
                          errors={errors?.email}
                          handleChange={handleChange}
                          placeholder=""
                          id="email_id"
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          mt: "2rem",
                          color: theme?.palette?.primary?.textBtn,
                        }}
                      >
                        Mobile number
                      </Typography>
                      <Box sx={{ mt: "1rem" }}>
                        <PhoneField
                          name="mobile_number"
                          handleChange={handleChange}
                          id="mobile_number"
                          value={values?.mobile_number}
                          isRequired={false}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Typography
                      sx={{
                        mt: "1rem",
                        color: theme?.palette?.primary?.textBtn,
                      }}
                    >
                      Message
                    </Typography>
                    <Box sx={{ mt: "1rem" }}>
                      <InputField
                        values={values?.message}
                        handleChange={handleChange}
                        name="message"
                        errors={errors?.message}
                        multiline={true}
                        minRow={3}
                        helperText={`${values?.message?.length || "0"}/500`}
                        length={150}
                        isRequired={false}
                        id="message"
                        placeholder="message"
                      />
                    </Box>
                  </Grid>
                  <Box
                    sx={{
                      mt: "1rem",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      type="button"
                      sx={{
                        background: "transparent",
                        border: "1px solid #FF7E00 ",
                        padding: "12px 24px 12px 24px",
                        width: "180px",
                      }}
                      onClick={() => {
                        navigate("/employer/home");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      sx={{ padding: "12px 24px 12px 24px", width: "180px" }}
                    >
                      Submit
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ mb: { xs: "5rem" } }}>
              <Typography
                variant="subtitle1"
                sx={{
                  mt: { sm: "6rem", xs: "2rem" },
                  color: theme?.palette?.primary?.textBtn,
                  fontWeight: 600,
                }}
              >
                Get in Touch With Us
              </Typography>
              <Box
                sx={{
                  mt: "1.5rem",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img
                  src={Call}
                  alt={"phone"}
                  style={{
                    backgroundColor: "#FFF1DE",
                    borderRadius: "30px",
                    padding: "10px",
                  }}
                />
                <Typography>+91 9717511088</Typography>
              </Box>

              <Box
                sx={{
                  mt: "1.5rem",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img
                  src={Message}
                  alt={"message"}
                  style={{
                    backgroundColor: "#FFF1DE",
                    borderRadius: "30px",
                    padding: "10px",
                  }}
                />
                <Typography>info@ekam.inc</Typography>
              </Box>
              <Box
                sx={{
                  mt: "1.5rem",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img
                  src={Location}
                  alt={"location"}
                  style={{
                    backgroundColor: "#FFF1DE",
                    borderRadius: "30px",
                    padding: "10px",
                  }}
                />
                <Typography>
                  EKAM Information Pvt. Ltd., Floor 9, Spaze I-Tech Park, A1
                  Tower, Sector 49, Gurgaon 122018, Haryana, India.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export default ContactUs;
